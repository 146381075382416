import React, { Component } from 'react'

interface Props {

}
interface State {

}

export default class NotAuthorized extends Component<Props, State> {
  state = {}

  render() {
    return (
      <div className="min-h-screen bg-gray-100 flex w-full items-center justify-center">
        <h4 className="text-2xl font-medium text-gray-800">Sorry but you are not authorized to view this page</h4>
      </div>
    )
  }
}
